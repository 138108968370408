import React, { createContext, useContext, useReducer } from 'react';

const GlobalContext = createContext();

const initialState = {
  postId: null,
  themeColor: 'black',
  postType: 'page',
  searchTerm: null,
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_POST_ID':
      return {
        ...state,
        postId: action.payload,
      };
    case 'SET_THEME_COLOR':
      return {
        ...state,
        themeColor: action.payload,
      };
    case 'SET_POST_TYPE':
      return {
        ...state,
        postType: action.payload,
      };
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const actions = {
    setPostId: (postId) => {
      dispatch({ type: 'SET_POST_ID', payload: postId });
    },
    setThemeColor: (color) => {
      dispatch({ type: 'SET_THEME_COLOR', payload: color });
    },
    setPostType: (type) => {
      dispatch({ type: 'SET_POST_TYPE', payload: type });
    },
    setSearchTerm: (term) => {
      dispatch({ type: 'SET_SEARCH_TERM', payload: term });
    },
  };

  return (
    <GlobalContext.Provider
      value={{
        state,
        ...actions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  return context;
};
