import React from 'react';
import fetch from 'cross-fetch';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { polyfill } from 'interweave-ssr';

import { GlobalProvider } from './src/context/GlobalContext';
import './src/styles/global.scss';
import './src/styles/tailwind.css';

polyfill();

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        posts: relayStylePagination(['where']),
      },
    },
  },
});

const link = new HttpLink({
  /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
  uri: `${process.env.GATSBY_WP_URL}/graphql`,
  /* Use fetch from cross-fetch to provide replacement for server environment */
  fetch,
});

const client = new ApolloClient({
  link,
  cache,
});

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </GlobalProvider>
);

export const onRouteUpdate = () => {
  window.nbPagesViews = typeof window.nbPagesViews === 'undefined' ? 1 : window.nbPagesViews += 1;

  const gptDataElem = document.querySelector('[gpt-data-type]');
  window.wordpress = [];
  if (gptDataElem) {
    switch (gptDataElem.getAttribute('gpt-data-type')) {
      case 'post':
        window.wordpress.postId = parseInt(gptDataElem.getAttribute('gpt-data-post-id'));
        window.wordpress.postCategories = gptDataElem.getAttribute('gpt-data-post-categories');
        window.wordpress.hidePostAds = !gptDataElem.getAttribute('gpt-data-post-show-ads');
        break;
      case 'category':
        window.wordpress.category = gptDataElem.getAttribute('gpt-data-category');
        break;
      case 'page':
        window.wordpress.page = gptDataElem.getAttribute('gpt-data-slug');
        break;
      default:
        break;
    }
  }

  // Facebook
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'ViewContent');
  }
  let isFacebookLoaded = false;
  if (!isFacebookLoaded && document.querySelector('.fb-post')) {
    setTimeout(() => {
      if (typeof window.loadFacebook === 'function') {
        window.loadFacebook();
        isFacebookLoaded = true;
      }
    }, 0);
  }

  // Instagram
  let isInstagramLoaded = false;
  if (!isInstagramLoaded && document.querySelector('.instagram-media')) {
    setTimeout(() => {
      if (typeof window.loadInstagram === 'function') {
        window.loadInstagram();
        isInstagramLoaded = true;
      }
    }, 0);
  }

  // Tiktok
  let isTikTokLoaded = false;
  if (!isTikTokLoaded && document.querySelector('.tiktok-embed')) {
    setTimeout(() => {
      if (typeof window.loadTiktok === 'function') {
        window.loadTiktok();
        isTikTokLoaded = true;
      }
    }, 0);
  }

  // Reddit
  let isRedditLoaded = false;
  if (!isRedditLoaded && document.querySelector('.reddit-embed-bq')) {
    setTimeout(() => {
      if (typeof window.loadReddit === 'function') {
        window.loadReddit();
        isRedditLoaded = true;
      }
    }, 0);
  }

  // Taboola
  if (window.wordpress.postId) {
    setTimeout(() => {
      // eslint-disable-next-line no-underscore-dangle
      window._taboola = window._taboola || [];
      if (window.nbPagesViews > 1) {
        // eslint-disable-next-line no-underscore-dangle
        window._taboola.push({ notify: 'newPageLoad' });
      }
      // eslint-disable-next-line no-underscore-dangle
      window._taboola.push({
        article: 'auto',
        url: document.querySelector('meta[property="og:url"]') ? document.querySelector('meta[property="og:url"]').content : window.location?.pathname,
      });
      if (document.getElementById('taboola-below-article')) {
        // eslint-disable-next-line no-underscore-dangle
        window._taboola.push({
          mode: 'alternating-thumbnails-a',
          container: 'taboola-below-article',
          placement: 'below-article',
          target_type: 'mix',
        });
      }
      if (document.getElementById('taboola-mid-article')) {
        // eslint-disable-next-line no-underscore-dangle
        window._taboola.push({
          mode: 'alternating-thumbnails-b-stream',
          container: 'taboola-mid-article',
          placement: 'Mid Article',
          target_type: 'mix',
        });
      }
      // eslint-disable-next-line no-underscore-dangle
      window._taboola.push({ flush: true });
    }, 0);
  }

  // GA and other page tracking
  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    (function gaTracking() {
      if (!window.gtag) {
        return;
      }

      let dimensions;
      let artists = [];
      let brands = [];
      let tags = [];

      const schemaElem = document.querySelector('script[type="application/ld+json"]');
      if (schemaElem) {
        const schema = JSON.parse(schemaElem.innerHTML)['@graph'];
        const articleSchema = schema.find(n => n['@type'].match(/Article|News|Posting/g));

        if (articleSchema) {
          const person = schema.find((p => p['@type'] === 'Person'));
          const [categoryName] = articleSchema.articleSection.slice(-1);
          dimensions = {
            author: person.name,
            date: articleSchema.datePublished,
            published_at: articleSchema.datePublished,
            category: categoryName,
          };

          artists = articleSchema.artists || [];
          brands = articleSchema.brands || [];
          tags = articleSchema.keywords || [];
        }
      }

      const pagePath = window.location
        ? window.location.pathname + window.location.search + window.location.hash
        : undefined;

      window.gtag('event', 'page_view', {
        page_path: pagePath,
        ...dimensions,
      });

      tags.forEach(tag => {
        window.gtag('event', 'article_tag', {
          page_path: pagePath,
          tag,
        });
      });

      artists.forEach(artist => {
        window.gtag('event', 'article_artist', {
          page_path: pagePath,
          artist,
        });
      });

      brands.forEach(brand => {
        window.gtag('event', 'article_brand', {
          page_path: pagePath,
          brand,
        });
      });
    })();

    (function trackOnTheIO() {
      const schemaElem = document.querySelector('script[type="application/ld+json"]');
      if (!schemaElem) {
        return;
      }
      const schema = JSON.parse(schemaElem.innerHTML)['@graph'];
      const pageSchema = schema.find(n => n['@type'] === 'WebPage');
      const collectionSchema = schema.find(n => n['@type'] === 'CollectionPage');
      const articleSchema = schema.find(n => n['@type'].match(/Article|News|Posting/g));
      const authorSchema = schema.find(n => n['@type'] === 'Person');
      const canonicalUrl = document.querySelector('[rel="canonical"]') ? document.querySelector('[rel="canonical"]').href : document.location.href;

      const ioConf = {
        page_url: document.location.href,
        page_url_canonical: canonicalUrl,
        page_title: pageSchema ? pageSchema.name : collectionSchema.name,
        page_type: document.location.pathname === '/' ? 'main' : 'default',
        page_language: 'en',
        user_status: '',
      };

      if (articleSchema) {
        const articleTags = [
          ...(articleSchema.keywords || []),
          ...(articleSchema.artists || []),
          ...(articleSchema.brands || []),
        ];

        ioConf.page_type = 'article';
        ioConf.tags = articleTags;
        ioConf.article_authors = [authorSchema.name];
        ioConf.article_categories = articleSchema.articleSection;
        // ioConf.article_subcategories = ['Subcategory 1', 'Subcategory 2'];
        ioConf.article_type = '';
        ioConf.article_word_count = articleSchema.wordCount;
        ioConf.article_publication_date = articleSchema.datePublished;
      }

      // eslint-disable-next-line no-underscore-dangle
      window._io_config = window._io_config || {};
      // eslint-disable-next-line no-underscore-dangle
      window._io_config['0.2.0'] = window._io_config['0.2.0'] || [];
      // eslint-disable-next-line no-underscore-dangle
      window._io_config['0.2.0'].push(ioConf);
    })();
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
};

export const onInitialClientRender = () => {
  (function addAdminBar() {
    if (document.cookie.includes('wp-settings-time')) {
      const l1 = document.createElement('link');
      l1.rel = 'stylesheet';
      l1.type = 'text/css';
      l1.href = `${process.env.GATSBY_WP_URL}/wp-includes/css/admin-bar.min.css`;
      document.getElementsByTagName('head')[0].appendChild(l1);

      const l2 = document.createElement('link');
      l2.rel = 'stylesheet';
      l2.type = 'text/css';
      l2.href = `${process.env.GATSBY_WP_URL}/wp-includes/css/dashicons.min.css`;
      document.getElementsByTagName('head')[0].appendChild(l2);

      document.querySelector('html').style.marginTop = '32px';
    }
  })();
};
